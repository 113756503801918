import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { PlanningCreateSchema, PlanningUpdateSchema, PlanningSchema, PlanningDetailsSchema, PlanningFilterSchema } from '../../types/api';


class PlanningsModule extends BaseCrudModule<PlanningSchema, PlanningCreateSchema, PlanningUpdateSchema, PlanningFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/plannings');
  }

  async getDetails(id: number):  Promise<AxiosResponse<PlanningDetailsSchema, any>> {
    return this.axios.get<PlanningDetailsSchema>(`${this.resourceURL}/${id}/details`);
  }

  async listDetails(filters?: PlanningFilterSchema):  Promise<AxiosResponse<PlanningDetailsSchema[], any>> {
    if (!filters) {
      return this.axios.get<PlanningDetailsSchema[]>(`${this.resourceURL}/details`);
    }
    return this.axios.get<PlanningDetailsSchema[]>(`${this.resourceURL}/details`, { params: { ...filters }});
  }
}

export default PlanningsModule;