export default defineNuxtRouteMiddleware((to, from) => {
  const { isAuthenticated, user } = useAuthStore()
  if (to.path !== '/login' && to.path !== '/verify-email-login' && isAuthenticated === false) {
    return navigateTo('/login')
  }

  // forward depending on user role
  const role = user?.role;
  if (to.path === '/') {
    if (role === 'superuser') {
      return navigateTo('/overview')
    }
    return navigateTo('/planning')
  }
})