<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UModals /> 
<UNotifications />
</template>

<script setup lang="ts">
import { appStartup } from './utils/startup';

const toast = useToast();

console.log('App started');

onMounted(() => {
  console.log('App mounted');
  toast.add({
    title: 'AnBord',
    description: 'Willkommen bei AnBord',
    timeout: 5000,
  });
});

// init app
appStartup();

</script>