import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { WorkspaceCreateSchema, WorkspaceUpdateSchema, WorkspaceSchema, WorkspaceFilterSchema } from '../../types/api';


class WorkspacesModule extends BaseCrudModule<WorkspaceSchema, WorkspaceCreateSchema, WorkspaceUpdateSchema, WorkspaceFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/workspaces');
  }
}

export default WorkspacesModule;