import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { PlanningAreaCreateSchema, PlanningAreaUpdateSchema, PlanningAreaSchema, PlanningAreaDetailsSchema, PlanningAreaFilterSchema } from '../../types/api';


class PlanningAreasModule extends BaseCrudModule<PlanningAreaSchema, PlanningAreaCreateSchema, PlanningAreaUpdateSchema, PlanningAreaFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/planningareas');
  }

  async getDetails(id: number):  Promise<AxiosResponse<PlanningAreaDetailsSchema, any>> {
    return this.axios.get<PlanningAreaDetailsSchema>(`${this.resourceURL}/${id}/details`);
  }

  async listDetails(filters?: PlanningAreaFilterSchema):  Promise<AxiosResponse<PlanningAreaDetailsSchema[], any>> {
    if (!filters) {
      return this.axios.get<PlanningAreaDetailsSchema[]>(`${this.resourceURL}/details`);
    }
    return this.axios.get<PlanningAreaDetailsSchema[]>(`${this.resourceURL}/details`, { params: { ...filters }});
  }

  async submit(id: number): Promise<AxiosResponse<PlanningAreaSchema, any>> {
    return this.axios.post<PlanningAreaSchema>(`${this.resourceURL}/${id}/submit`);
  }

}

export default PlanningAreasModule;