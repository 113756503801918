import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseModule } from "./base";


class AdminModule extends BaseModule {
  constructor(axios: AxiosInstance) {
    super(axios, '/admin');
  }

  async createTestWorkspace(): Promise<AxiosResponse<any, any>> {
    return this.axios.get(`${this.resourceURL}/generate-test`);
  }
}

export default AdminModule;