import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { DimensionCreateSchema, DimensionUpdateSchema, DimensionSchema, DimensionFilterSchema } from '../../types/api';


class DimensionsModule extends BaseCrudModule<DimensionSchema, DimensionCreateSchema, DimensionUpdateSchema, DimensionFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/dimensions');
  }
}

export default DimensionsModule;