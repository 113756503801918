import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { TableItemCreateSchema, TableItemUpdateSchema, TableItemSchema, TableItemFilterSchema } from '../../types/api';


class TableItemsModule extends BaseCrudModule<TableItemSchema, TableItemCreateSchema, TableItemUpdateSchema, TableItemFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/tableitems');
  }
}

export default TableItemsModule;