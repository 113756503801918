import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { PlanningAreaTableCreateSchema, PlanningAreaTableUpdateSchema, PlanningAreaTableSchema, PlanningAreaTableFilterSchema } from '../../types/api';


class PlanningAreaTablesModule extends BaseCrudModule<PlanningAreaTableSchema, PlanningAreaTableCreateSchema, PlanningAreaTableUpdateSchema, PlanningAreaTableFilterSchema> {

  constructor(axios: AxiosInstance) {
    super(axios, '/planningareatables');
  }
}

export default PlanningAreaTablesModule;