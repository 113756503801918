import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { TableValueCreateSchema, TableValueUpdateSchema, TableValueSchema, TableValueReportingSchema, TableValueFilterSchema } from '../../types/api';


class TableValuesModule extends BaseCrudModule<TableValueSchema, TableValueCreateSchema, TableValueUpdateSchema, TableValueFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/tablevalues');
  }
  
  async listReporting(tableId: number, dimensionItemId: number, filters?: TableValueFilterSchema):  Promise<AxiosResponse<TableValueReportingSchema[], any>> {
    if (!filters) {
      return this.axios.get<TableValueReportingSchema[]>(`${this.resourceURL}/reporting/${tableId}/${dimensionItemId}`);
    }
    return this.axios.get<TableValueReportingSchema[]>(`${this.resourceURL}/reporting/${tableId}/${dimensionItemId}`, { params: { ...filters }});
  }
}

export default TableValuesModule;