import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule, BaseModule } from "./base";
import type { UserSchema, UserCreateSchema, UserUpdateSchema, UserFilterSchema } from '../../types/api';

class UsersModule extends BaseCrudModule<UserSchema, UserCreateSchema, UserUpdateSchema, UserFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/users');
  }

  async me(): Promise<AxiosResponse<UserSchema, any>> {
    //debugger;
    return this.axios.get<UserSchema>(`${this.resourceURL}/me`);
  }
}

export default UsersModule;