import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { ScenarioCreateSchema, ScenarioUpdateSchema, ScenarioSchema, ScenarioFilterSchema } from '../../types/api';


class ScenariosModule extends BaseCrudModule<ScenarioSchema, ScenarioCreateSchema, ScenarioUpdateSchema, ScenarioFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/scenarios');
  }
}

export default ScenariosModule;