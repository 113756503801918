import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { DimensionItemCreateSchema, DimensionItemUpdateSchema, DimensionItemSchema, DimensionItemFilterSchema } from '../../types/api';


class DimensionItemsModule extends BaseCrudModule<DimensionItemSchema, DimensionItemCreateSchema, DimensionItemUpdateSchema, DimensionItemFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/dimensionitems');
  }
}

export default DimensionItemsModule;