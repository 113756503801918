import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { DataTableValueCreateSchema, DataTableValueUpdateSchema, DataTableValueSchema, DataTableValueFilterSchema } from '../../types/api';


class DataTableValuesModule extends BaseCrudModule<DataTableValueSchema, DataTableValueCreateSchema, DataTableValueUpdateSchema, DataTableValueFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/datatablevalues');
  }
}

export default DataTableValuesModule;