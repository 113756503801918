import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseModule } from "./base";
import type { GridTableRowSchema, GridTableRowFilterSchema, GridTableItemSchema, GridTableItemFilterSchema, GridTableValueFilterSchema, GridTableValueSchema, GridTableItemCreateSchema, GridTableItemUpdateSchema, GridTableValueCreateSchema, GridTableCommentFilterSchema, GridTableCommentSchema, GridTableCommentCreateSchema } from '../../types/api';


class GridModule extends BaseModule {
  constructor(axios: AxiosInstance) {
    super(axios, '/grid');
  }

  // table rows

  async listTableRows(filters: GridTableRowFilterSchema): Promise<AxiosResponse<GridTableRowSchema[], any>> {
    return this.axios.get<GridTableRowSchema[]>(`${this.resourceURL}/tablerows`, { params: { ...filters }});
  }

  // table items

  async listTableItems(filters: GridTableItemFilterSchema): Promise<AxiosResponse<GridTableItemSchema[], any>> {
    return this.axios.get<GridTableItemSchema[]>(`${this.resourceURL}/tableitems`, { params: { ...filters }});
  }

  async createTableItem(data: GridTableItemCreateSchema): Promise<AxiosResponse<GridTableItemSchema, any>> {
    return this.axios.post<GridTableItemSchema>(`${this.resourceURL}/tableitems`, data);
  }

  async updateTableItem(id: number, data: GridTableItemUpdateSchema): Promise<AxiosResponse<GridTableItemSchema, any>> {
    return this.axios.put<GridTableItemSchema>(`${this.resourceURL}/tableitems/${id}`, data);
  }

  async deleteTableItem(id: number): Promise<AxiosResponse<any, any>> {
    return this.axios.delete(`${this.resourceURL}/tableitems/${id}`);
  }

  // table values

  async listTableValues(filters: GridTableValueFilterSchema): Promise<AxiosResponse<GridTableValueSchema[], any>> {
    return this.axios.get<GridTableValueSchema[]>(`${this.resourceURL}/tablevalues`, { params: { ...filters }});
  }
  async saveTableValues(data: GridTableValueCreateSchema): Promise<AxiosResponse<GridTableValueSchema[], any>> {
    return this.axios.post<GridTableValueSchema[]>(`${this.resourceURL}/tablevalues`, data);
  }

  // table comments

  async listTableComments(filters: GridTableCommentFilterSchema): Promise<AxiosResponse<GridTableCommentSchema[], any>> {
    return this.axios.get<GridTableCommentSchema[]>(`${this.resourceURL}/tablecomments`, { params: { ...filters }});
  }
  async saveTableComments(data: GridTableCommentCreateSchema): Promise<AxiosResponse<GridTableCommentSchema[], any>> {
    return this.axios.post<GridTableCommentSchema[]>(`${this.resourceURL}/tablecomments`, data);
  }
}

export default GridModule;