import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { TableCreateSchema, TableUpdateSchema, TableSchema, TableFilterSchema } from '../../types/api';


class TablesModule extends BaseCrudModule<TableSchema, TableCreateSchema, TableUpdateSchema, TableFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/tables');
  }
}

export default TablesModule;