import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { TableRowCreateSchema, TableRowUpdateSchema, TableRowSchema, TableRowFilterSchema } from '../../types/api';


class TableRowsModule extends BaseCrudModule<TableRowSchema, TableRowCreateSchema, TableRowUpdateSchema, TableRowFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/tablerows');
  }
}

export default TableRowsModule;