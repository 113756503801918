import type { ILoginInput, IRefreshTokenInput, IUser, IEMailLoginInput, IEMailTokenInput } from "~/types/api"

interface IAuthState {
  accessToken: string | null
  refreshToken: string | null
  user: IUser | null
}

const STORAGE_KEY = 'auth';

export const useAuthStore = defineStore('auth', {
  state: (): IAuthState => {
    const storedState = localStorage.getItem(STORAGE_KEY);
    if (storedState) {
      return JSON.parse(storedState);
    } else {
      return {
        accessToken: null,
        refreshToken: null,
        user: null,
      }
    }
  },
  getters: {
    isAuthenticated: (state): boolean => !!state.accessToken,
  },
  actions: {
    setTokens(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.persistState();
    },
    setUser(user: IUser) {
      this.user = user;
      this.persistState();
    },
    clearAuth() {
      this.accessToken = null;
      this.refreshToken = null;
      this.user = null;
      localStorage.removeItem(STORAGE_KEY);
    },
    persistState() {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({
        refreshToken: this.refreshToken,
        accessToken: this.accessToken,
        user: this.user,
      }));
    },
    async loadUser() {
      console.log('Loading user...');
      const { $api } = useNuxtApp();
      const { data: user } = await $api.users.me();
      this.setUser(user);
    },
    async login(credentials: ILoginInput) {
      //debugger;
      const { $api } = useNuxtApp();
      const { data } = await $api.auth.login(credentials); 
      this.setTokens(data.access, data.refresh);
      this.loadUser();
    },
    async requestEmaillogin(credentials: IEMailLoginInput) {
      //debugger;
      const { $api } = useNuxtApp();
      const { data } = await $api.auth.requestEmailLogin(credentials);
      return true;
    },
    async verifyEmaillogin(token: string) {
      //debugger;
      const { $api } = useNuxtApp();
      const tokenInput: IEMailTokenInput = { token: token };
      const { data } = await $api.auth.verifyEmailLogin(tokenInput); 
      this.setTokens(data.access, data.refresh);
      this.loadUser();
      return true;
    },
    async refreshTokens() {
      const { $api } = useNuxtApp();
      if (!this.refreshToken) throw new Error('No refresh token available')
      try {
        const { data } = await $api.auth.refreshToken({ refresh: this.refreshToken });
        this.setTokens(data.access, data.refresh);
      } catch (error) {
        this.clearAuth();
        const router = useRouter();
        router.push('/login');
      }
    },
    async logout() {
      const { $api } = useNuxtApp();
      if (!this.refreshToken) throw new Error('No refresh token available')
      try {
        const result = await $api.auth.logout({ refresh: this.refreshToken });
      } catch (error) {
        console.error('Logout failed: ', error);
      } finally {
        this.clearAuth();
      }
    },
    async init() {
      if (this.refreshToken) {
        // check if refresh token is still valid and load user
        try {
          await this.refreshTokens();
          await this.loadUser();
        } catch (error) {
          this.clearAuth();
        }
      } else {
        this.clearAuth();
      }
    }
  },
})