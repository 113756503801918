import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { DataTableCreateSchema, DataTableUpdateSchema, DataTableSchema, DataTableFilterSchema } from '../../types/api';


class DataTablesModule extends BaseCrudModule<DataTableSchema, DataTableCreateSchema, DataTableUpdateSchema, DataTableFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/datatables');
  }

  async updateTables(): Promise<AxiosResponse<any, any>> {
    return this.axios.post(`${this.resourceURL}/update-tables`);
  }
}

export default DataTablesModule;