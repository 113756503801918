import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseModule } from "./base";
import type { SnapshotCreateSchema, SnapshotFilterSchema, SnapshotSchema } from "~/types/api";


class SnapshotModule extends BaseModule {
  constructor(axios: AxiosInstance) {
    super(axios, '/snapshots');
  }

  async list(filters?: SnapshotFilterSchema):  Promise<AxiosResponse<SnapshotSchema[], any>> {
    if (!filters) {
      return this.axios.get<SnapshotSchema[]>(this.resourceURL);
    }
    return this.axios.get<SnapshotSchema[]>(this.resourceURL, { params: { ...filters }});
  }

  async get(id: number):  Promise<AxiosResponse<SnapshotSchema, any>> {
    return this.axios.get<SnapshotSchema>(`${this.resourceURL}/${id}`);
  }

  async create(data: SnapshotCreateSchema): Promise<AxiosResponse<SnapshotSchema, any>> {
    return this.axios.post<SnapshotSchema>(this.resourceURL, data);
  }

  async delete(id: number): Promise<AxiosResponse<any, any>> {
    return this.axios.delete(`${this.resourceURL}/${id}`);
  }
}

export default SnapshotModule;