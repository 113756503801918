import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export class BaseModule {
  axios: AxiosInstance;
  resourceURL: string; 

  constructor(axios: AxiosInstance, resourceURL: string) {
    this.axios = axios;
    this.resourceURL = resourceURL;
  }
}

export class BaseCrudModule<T, TCreate = any, TUpdate = any, TFilter = any> extends BaseModule {
  async list(filters?: TFilter):  Promise<AxiosResponse<T[], any>> {
    if (!filters) {
      return this.axios.get<T[]>(this.resourceURL);
    }
    return this.axios.get<T[]>(this.resourceURL, { params: { ...filters }});
  }

  async get(id: number):  Promise<AxiosResponse<T, any>> {
    return this.axios.get<T>(`${this.resourceURL}/${id}`);
  }

  async create(data: TCreate): Promise<AxiosResponse<T, any>> {
    return this.axios.post<T>(this.resourceURL, data);
  }

  async update(id: number, data: TUpdate): Promise<AxiosResponse<T, any>> {
    return this.axios.put<T>(`${this.resourceURL}/${id}`, data);
  }

  async delete(id: number): Promise<AxiosResponse<any, any>> {
    return this.axios.delete(`${this.resourceURL}/${id}`);
  }
}