import axios, { type AxiosInstance } from 'axios';
import AuthModule from '../repository/modules/auth';
import UsersModule from '../repository/modules/users';

import WorkspacesModule from '~/repository/modules/workspaces';
import DataTablesModule from '~/repository/modules/datatables'; 
import DataTableValuesModule from '~/repository/modules/datatablevalues';
import DimensionsModule from '~/repository/modules/dimensions';
import DimensionItemsModule from '~/repository/modules/dimensionitems';
import ScenariosModule from '~/repository/modules/scenarios';
import TablesModule from '~/repository/modules/tables';
import TableRowsModule from '~/repository/modules/tablerows';
import TableItemsModule from '~/repository/modules/tableitems';
import TableValuesModule from '~/repository/modules/tablevalues';
import SnapshotModule from '~/repository/modules/snapshot';
import PlanningsModule from '~/repository/modules/plannings';
import PlanningAreasModule from '~/repository/modules/planningareas';
import PlanningAreaDimensionItemsModule from '~/repository/modules/planningareadimensionitems';
import PlanningAreaTablesModule from '~/repository/modules/planningareatables';
import GridModule from '~/repository/modules/grid';

import AdminModule from '~/repository/modules/admin';


interface IApiInstance {
  auth: AuthModule,
  users: UsersModule,

  workspaces: WorkspacesModule,

  dimensions: DimensionsModule,
  dimensionItems: DimensionItemsModule,
  scenarios: ScenariosModule,

  dataTables: DataTablesModule,
  dataTableValues: DataTableValuesModule,

  tables: TablesModule,
  tableRows: TableRowsModule,
  tableItems: TableItemsModule,
  tableValues: TableValuesModule,

  snapshots: SnapshotModule,

  plannings: PlanningsModule,
  planningAreas: PlanningAreasModule,
  planningAreaDimensionItems: PlanningAreaDimensionItemsModule,
  planningAreaTables: PlanningAreaTablesModule,

  grid: GridModule,

  admin: AdminModule,
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const toast = useToast();

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: config.public.apiBaseUrl,
  });

  // loading indicator
  const { progress, isLoading, start, finish, clear } = useLoadingIndicator({
    duration: 2000,
    throttle: 200,
    // This is how progress is calculated by default
    estimatedProgress: (duration, elapsed) => (2 / Math.PI * 100) * Math.atan(elapsed / duration * 100 / 50)
  })

  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      start();
      const token = authStore.accessToken;
      if (token && !config.url?.includes('auth')) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      clear();
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      clear();
      if (useRuntimeConfig().app.debug) {
        toast.add({
          title: 'DEBUG: API',
          description: 'Response: ' + `${response.status} ${response.statusText}` + ' ' + JSON.stringify(response.data),
          timeout: 5000,
        });
      }
      return response;
    },
    async (error) => {
      clear();
      const originalRequest = error.config;
      if (useRuntimeConfig().app.debug) {
        toast.add({
          title: 'DEBUG: API',
          description: 'Response error: ' + `${error.response.status} ${error.response.statusText}` + ' ' + JSON.stringify(error),
          timeout: 5000,
        });
      } else {
        toast.add({
          title: 'Problem bei der Kommunikation',
          description: 'Bei der Durchführung einer Abfrage bzw. beim Speichern ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
          //timeout: 5000,
        });
      }
      if (error.response.status === 401 && !originalRequest.url!.includes('refresh-token')) {
        try {
          if (useRuntimeConfig().app.debug) {
            toast.add({
              title: 'DEBUG: API',
              description: 'Refresh token abgelaufen',
              timeout: 5000,
            });
          }
          
          await authStore.refreshTokens();
          originalRequest.headers['Authorization'] = `Bearer ${authStore.accessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure (e.g., logout user)
          if (error.response.status === 401) {
            toast.add({
              title: 'Ihre Anmeldung ist abgelaufen',
              description: 'Bitte loggen Sie sich erneut ein.',
              timeout: 3000,
            });

            authStore.logout();
          }
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  // Create modules
  const modules: IApiInstance = {
    auth: new AuthModule(axiosInstance),
    users: new UsersModule(axiosInstance),

    workspaces: new WorkspacesModule(axiosInstance),
    
    dimensions: new DimensionsModule(axiosInstance),
    dimensionItems: new DimensionItemsModule(axiosInstance),
    scenarios: new ScenariosModule(axiosInstance),
    
    dataTables: new DataTablesModule(axiosInstance),
    dataTableValues: new DataTableValuesModule(axiosInstance),

    tables: new TablesModule(axiosInstance),
    tableRows: new TableRowsModule(axiosInstance),
    tableItems: new TableItemsModule(axiosInstance),
    tableValues: new TableValuesModule(axiosInstance),

    snapshots: new SnapshotModule(axiosInstance),
    
    plannings: new PlanningsModule(axiosInstance),
    planningAreas: new PlanningAreasModule(axiosInstance),
    planningAreaDimensionItems: new PlanningAreaDimensionItemsModule(axiosInstance),
    planningAreaTables: new PlanningAreaTablesModule(axiosInstance),

    grid: new GridModule(axiosInstance),

    admin: new AdminModule(axiosInstance),

  };

  return {
    provide: {
      api: modules
    }
  };
});