import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseModule } from "./base";
import type { RequestEmailLoginSchema, EmailTokenSchema, LoginSchema, RefreshTokenSchema, TokenSchema } from '../../types/api';
import type { MessageResponseSchema } from '../../types/api-custom';

class AuthModule extends BaseModule {
  constructor(axios: AxiosInstance) {
    super(axios, '/auth');
  }

  async login(data: LoginSchema): Promise<AxiosResponse<TokenSchema, any>> {
    return this.axios.post<TokenSchema>(`${this.resourceURL}/login`, data);
  }

  async requestEmailLogin(data: RequestEmailLoginSchema): Promise<AxiosResponse<any, any>> {
    return this.axios.post<any>(`${this.resourceURL}/request-email-login`, data);
  }

  async verifyEmailLogin(data: EmailTokenSchema): Promise<AxiosResponse<TokenSchema, any>> {
    return this.axios.post<any>(`${this.resourceURL}/verify-email-login`, data);
  }

  async refreshToken(data: RefreshTokenSchema): Promise<AxiosResponse<TokenSchema, any>> {
    return this.axios.post<TokenSchema>(`${this.resourceURL}/refresh-token`, data);
  }

  async logout(data: RefreshTokenSchema): Promise<AxiosResponse<MessageResponseSchema, any>> {
    return this.axios.post<MessageResponseSchema>(`${this.resourceURL}/logout`, data);
  }
}

export default AuthModule;