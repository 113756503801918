import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { BaseCrudModule } from "./base";
import type { PlanningAreaDimensionItemCreateSchema, PlanningAreaDimensionItemUpdateSchema, PlanningAreaDimensionItemSchema, PlanningAreaDimensionItemFilterSchema } from '../../types/api';


class PlanningAreaDimensionItemsModule extends BaseCrudModule<PlanningAreaDimensionItemSchema, PlanningAreaDimensionItemCreateSchema, PlanningAreaDimensionItemUpdateSchema, PlanningAreaDimensionItemFilterSchema> {
  constructor(axios: AxiosInstance) {
    super(axios, '/planningareadimensionitems');
  }
}

export default PlanningAreaDimensionItemsModule;